import React from 'react';

import './App.css';
import Navbar from './navbar';

function App() {
  return (
    <div className="App">
     <Navbar></Navbar>
    </div>
  );
}

export default App;
