import React from 'react';
import cx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { useContainedCardHeaderStyles } from '@mui-treasury/styles/cardHeader/contained';
import { useSoftRiseShadowStyles } from '@mui-treasury/styles/shadow/softRise';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import CardMedia from '@material-ui/core/CardMedia';
import TextInfoCardContent from '@mui-treasury/components/cardContent/textInfo';
import { useBlogCardContentStyles } from '@mui-treasury/styles/cardContent/blog';
import { useOverShadowStyles } from '@mui-treasury/styles/shadow/over';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    marginTop: 30,
    marginLeft: 15,
    marginRight: 15,
    borderRadius: spacing(2), // 16px
    transition: '0.3s',
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
    position: 'relative',
    maxWidth: '100%',

    overflow: 'initial',
    background: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: spacing(2),
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      paddingTop: spacing(2),
    },
  },
  media: {
    width: '50%',
    marginLeft: spacing(3),
    // marginRight: 'auto',
    marginTop: spacing(-3),
    height: 0,
    paddingBottom: '48%',
    borderRadius: spacing(2),
    backgroundColor: '#fff',
    position: 'relative',
    [breakpoints.up('lg')]: {
      width: '100%',
      marginLeft: spacing(0),
      marginTop: 0,
      transform: 'translateX(-8px)',
    },
    '&:after': {
      content: '" "',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      // backgroundImage: 'linear-gradient(147deg, #1A237E -20%, #3949AB -30%)',
      borderRadius: spacing(2), // 16
      opacity: 0.5,
    },
  },
  content: {
    padding: 24,
  },
  cta: {
    marginTop: 24,
    textTransform: 'initial',
  },
  card: {
    marginTop: 40,
    marginLeft: 15,
    marginRight: 15,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    transition: '0.3s',
    width: '100%',
    overflow: 'initial',
    background: '#ffffff',
    fontFamily: "Andale Mono, monospace",
    fontStretch: "expanded",
    fontSize: 17,

  },
  content: {
    textAlign: 'left',
    overflowX: 'auto',
  },
  paper: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: spacing(1),
      width: spacing(16),
      height: spacing(16),
    },
  }
}));




const ElevatedHeaderCard = () => {
  const classes = useStyles();
  const cardHeaderStyles = useContainedCardHeaderStyles();
  const cardShadowStyles = useSoftRiseShadowStyles({ inactive: true });
  const cardHeaderShadowStyles = useFadedShadowStyles();
  const shadowStyles = useOverShadowStyles();
  const {

    ...cardContentStyles
  } = useBlogCardContentStyles();
  return (
    <div>
      <br></br>
      <Grid container spacing={3}>

        <Grid item xs={11}>
          <Card className={cx(classes.card, cardShadowStyles.root)}>
            <CardHeader
              className={cardHeaderShadowStyles.root}
              classes={cardHeaderStyles}
              title={'Management'}

            />
            <CardContent className={classes.content}>
              <TextInfoCardContent
                classes={cardContentStyles}
                body={
                  "KRM Cashew industry is a privately owned company present since 1990's Involved in exporting cashew nut kernels country wide by satisfying plenty of customers. The company started when all the processing was done with man power and now company processing with less man power by using recent technologies."
                }
              />
              <TextInfoCardContent
                classes={cardContentStyles}
                body={
                  "The company is very familiar with cashew bussiness, Have extensive experience and knowing the several types species under which processed cashew nut kernels are graded. We belive that quality of cashew nut depends on the raw materials first, so great care is taken in selecting raw cashew nut and Strict vigil at every stage of processing. We ensure that the product is processed and packed as per the standards prescribed by drawing samples from the finished product. Ultimatley our company's main goal is to maintain good quality of cashew nut kernels, assured price to the costumer and steady relaible supply."
                }
              />
            </CardContent>
          </Card>
        </Grid></Grid>
      <br></br>

      <Card className={cx(classes.root, shadowStyles.root)}>
        <CardMedia
          className={classes.media}
          image={
            require("./dadphoto.jpg")
          }
        />
        <CardContent>

          <TextInfoCardContent
            classes={cardContentStyles}
            overline={'Kurma Rao'}
            heading={'CEO/Founder'}
            body={
              "I am having experience in this industry since 22 years. Active engagment in all stages of value chain starting from procurment of highest quality of raw cashew nut from farmers to processing and distribution of superior quality of cashew nuts to all over the country. I assure the coustomer with good quality of cashews."
            }
          />

        </CardContent>
      </Card>






      <Grid container spacing={3}>

        <Grid item xs={11}>
          <Card className={cx(classes.card, cardShadowStyles.root)}>
            <CardHeader
              className={cardHeaderShadowStyles.root}
              classes={cardHeaderStyles}
              title={'Our Values'}

            />
            <CardContent className={cx(classes.content, classes.paper)}>
              <Paper elevation={3} style={{ textAlign: "center", fontWeight: "bold", fontSize: 18 }}>
                <br></br>
                <br></br>
    Coustomer Value

    </Paper>
              <Paper elevation={3} style={{ textAlign: "center", fontWeight: "bold", fontSize: 18 }}>
                <br></br>
                <br></br>
    Quality

    </Paper>
              <Paper elevation={3} style={{ textAlign: "center", fontWeight: "bold", fontSize: 18 }} >
                <br></br>
                <br></br>
    Assured Price

    </Paper>
            </CardContent>
          </Card>
        </Grid></Grid>
    </div>

  );
};


export default ElevatedHeaderCard;