import React, { useState } from 'react'
import cashewTypes from './json/cashewTypes.json';
import cx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import TextInfoCardContent from '@mui-treasury/components/cardContent/textInfo';
import { useBlogCardContentStyles } from '@mui-treasury/styles/cardContent/blog';
import { useOverShadowStyles } from '@mui-treasury/styles/shadow/over';
import { VerticleButton } from "react-scroll-up-button";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Alert from '@material-ui/lab/Alert';
import ZoomInTwoToneIcon from '@material-ui/icons/ZoomInTwoTone';
const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    margin: 'auto',
    borderRadius: spacing(2), // 16px
    transition: '0.3s',
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
    position: 'relative',
    maxWidth: 500,
    marginLeft: 'auto',
    overflow: 'initial',
    background: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: spacing(2),
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      paddingTop: spacing(2),
    },
  },
  media: {
    width: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: spacing(-3),
    height: 0,
    paddingBottom: '48%',
    borderRadius: spacing(2),
    backgroundColor: '#fff',
    position: 'relative',
    [breakpoints.up('md')]: {
      width: '100%',
      marginLeft: spacing(-3),
      marginTop: 0,
      transform: 'translateX(-8px)',
    },
    '&:after': {
      content: '" "',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      //   backgroundImage: 'linear-gradient(147deg, #fe8a39 0%, #fd3838 74%)',
      borderRadius: spacing(2), // 16
      opacity: 0.5,
    },
  },
  content: {
    padding: 24,
  },
  cta: {
    marginTop: 24,
    textTransform: 'initial',
  },
}))

const Products = () => {
  const classes = useStyles();

  const [modal, setModal] = useState(false);
  const [imag, setimag] = useState("");
  const [type, settype] = useState("")
  const toggle = () => setModal(!modal);
  const openModel = (source, type) => {
    setModal(true)
    setimag(source)
    settype(type)
  };
  const {

    ...cardContentStyles
  } = useBlogCardContentStyles();
  const shadowStyles = useOverShadowStyles();
  return (
    <div style={{ margin: 10 }}>
      <br></br>
      <Alert variant="filled" color="success" severity="info">
        <h4>Contact us for Orders at 9437658870</h4>

      </Alert>
      <br></br>
      <Grid container spacing={1}>
        <br></br>
        {cashewTypes.cashewTypes.map((type, index) => {
          return (<Grid key={index} item xs={6}>
            <br></br>


            <Card className={cx(classes.root, shadowStyles.root)} onClick={() =>
              openModel(require(type.source + ""), type.type)}>
              <CardMedia
                className={classes.media}
                image={
                  require(type.source + "")
                }
              />
              <CardContent>

                <TextInfoCardContent
                  classes={cardContentStyles}
                  // overline={"contct us at 9437658870 "}
                  heading={<>{type.type} </>}
                  body={<ZoomInTwoToneIcon></ZoomInTwoToneIcon>}
                />

              </CardContent>
            </Card>
          </Grid>

          )
        })}

      </Grid>
      <Modal isOpen={modal} toggle={toggle} >
        <ModalHeader toggle={toggle}>{type}</ModalHeader>
        <ModalBody>
          <img src={imag} style={{ width: "100%", height: "100%" }}></img>
        </ModalBody>

      </Modal>
      <VerticleButton />
    </div>
  )
}

export default Products
