import React from 'react';
import cx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { useContainedCardHeaderStyles } from '@mui-treasury/styles/cardHeader/contained';
import { useSoftRiseShadowStyles } from '@mui-treasury/styles/shadow/softRise';
import { useFadedShadowStyles } from '@mui-treasury/styles/shadow/faded';
import CardMedia from '@material-ui/core/CardMedia';
import TextInfoCardContent from '@mui-treasury/components/cardContent/textInfo';
import { useBlogCardContentStyles } from '@mui-treasury/styles/cardContent/blog';
import { useOverShadowStyles } from '@mui-treasury/styles/shadow/over';
import Grid from '@material-ui/core/Grid';
import image from './dadphoto.jpg'
import Paper from '@material-ui/core/Paper';
import cashewTypes from './json/cashewTypes.json';
import { Link } from 'react-router-dom';
import Button from "@material-ui/core/Button";
import NavigateNextSharpIcon from '@material-ui/icons/NavigateNextSharp';
import MobileStepper from '@material-ui/core/MobileStepper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Alert from '@material-ui/lab/Alert';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const caroselImg = [{
  label: 'carosel1',
  imgPath: require('./images/carosel/carosel1.png')
  // }, {
  //   label: 'carosel2',
  //   imgPath: require('./images/carosel/carosel2.jpg')
},
{
  label: 'carosel3',
  imgPath: require('./images/carosel/carosel3.jpg')
},
{
  label: 'carosel4',
  imgPath: require('./images/carosel/carosel4.jpg')
}]

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    marginTop: 30,
    marginLeft: 15,
    marginRight: 15,
    borderRadius: spacing(2), // 16px
    transition: '0.3s',
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
    position: 'relative',
    maxWidth: '100%',

    overflow: 'initial',
    background: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: spacing(2),
    [breakpoints.up('md')]: {
      flexDirection: 'row',
      paddingTop: spacing(2),
    },
  },
  media: {
    width: '50%',
    marginLeft: spacing(1),
    // marginRight: 'auto',
    marginTop: spacing(-3),
    height: 0,
    paddingBottom: '48%',
    borderRadius: spacing(2),
    backgroundColor: '#fff',
    position: 'relative',
    [breakpoints.up('lg')]: {
      width: '100%',
      marginLeft: spacing(0),
      marginTop: 0,
      transform: 'translateX(-8px)',
    },
    '&:after': {
      content: '" "',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',

      borderRadius: spacing(2), // 16
      opacity: 0.5,
    },
  },
  content: {
    padding: 24,
  },
  cta: {
    marginTop: 24,
    textTransform: 'initial',
  },
  card: {
    marginTop: 40,
    marginLeft: 15,
    marginRight: 15,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    transition: '0.3s',
    width: '100%',
    overflow: 'initial',
    background: '#ffffff',
    fontFamily: "Andale Mono, monospace",
    fontStretch: "expanded",
    fontSize: 17,

  },
  content: {
    textAlign: 'left',
    overflowX: 'auto',
  },
  img: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '300px',
    display: 'block',
    maxWidth: '100%',
    overflow: 'hidden',
    width: '100%',
    maxWidth: '700px',
  },
}));


const Home = () => {
  const classes = useStyles();
  const cardHeaderStyles = useContainedCardHeaderStyles();
  const cardShadowStyles = useSoftRiseShadowStyles({ inactive: true });
  const cardHeaderShadowStyles = useFadedShadowStyles();
  const shadowStyles = useOverShadowStyles();
  const theme = useTheme();
  const {

    ...cardContentStyles
  } = useBlogCardContentStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = caroselImg.length;

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStepChange = step => {
    setActiveStep(step);
  };
  return (
    <div><div style={{ margin: 5, backgroundColor: "rgba(0,0,0,0.0)" }}>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >

        {caroselImg.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 3 ? (
              <img className={classes.img} src={step.imgPath} alt={step.label} />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="dots"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>

            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}

          </Button>
        }

      />
    </div>
      <Grid container spacing={3}>

        <Grid item xs={11}>
          <Card className={cx(classes.card, cardShadowStyles.root)}>
            <CardHeader
              className={cardHeaderShadowStyles.root}
              classes={cardHeaderStyles}
              title={'Management'}

            />
            <CardContent className={classes.content}>
              <TextInfoCardContent
                classes={cardContentStyles}
                body={
                  "KRM Cashew industry is a privately owned company present since 1990's Involved in exporting cashew nut kernels country wide by satisfying plenty of customers. The company started when all the processing was done with man power and now company processing with less man power by using recent technologies."
                }
              />
              <span style={{ position: 'relative', bottom: '0px', left: '12px' }}><Link to={'/About'} >Read More<NavigateNextSharpIcon></NavigateNextSharpIcon></Link></span>
            </CardContent>



          </Card >
        </Grid></Grid>
      <br></br>
      <Alert variant="filled" color="success" severity="info">
        <h4>Contact us for Orders at 9437658870</h4>

      </Alert>
      <Grid container spacing={1}>

        <br></br>
        {cashewTypes.cashewTypes.slice(0, 4).map((type, index) => {
          return (<Grid key={index} item xs={6}>
            <br></br>


            <Card className={cx(classes.root, shadowStyles.root)} >
              <CardMedia
                className={classes.media}
                image={
                  require(type.source + "")
                }
              />
              <CardContent>

                <TextInfoCardContent
                  classes={cardContentStyles}
                  //overline={"contct us at 9437658870 "}
                  heading={type.type}
                //body={" We supply bulck products only."}
                />

              </CardContent>
            </Card>
          </Grid>

          )
        })}</Grid><br></br><div><Link to={'/Products'}><Button color="primary" variant="contained" style={{ marginRight: '1%' }}>See more Products <NavigateNextSharpIcon></NavigateNextSharpIcon></Button></Link></div>
      <br></br>
    </div>
  )
}

export default Home

