import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';

import ListItemText from '@material-ui/core/ListItemText';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import green from '@material-ui/core/colors/green';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Home from './home'
import About from './about';
import Products from './products';
import Locate from './locate';
const theme = createMuiTheme({
  palette: {

    primary: green,
    //   secondary: purple,
  },

});
const useStyles = makeStyles({

  root: {
    flexGrow: 1,
  },

  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function TemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >

      <List>

        <ListItem >
          <img src={require('./Krmicon.png')} style={{ height: '95px', width: '180px' }}></img>
        </ListItem>

      </List>
      <Divider />
      <List>
        {['Home', 'About', 'Products', 'Locate Us'].map((text, index) => (<Link to={'/' + text} style={{ textDecoration: 'none' }}>
          <ListItem button key={text}>
            {/* <ListItemIcon>{index % 4 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
            <ListItemText variant="h1" primary={text} />
          </ListItem></Link>
        ))}
      </List>

    </div>
  );


  return (
    <div>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <AppBar position="static" theme={theme} color="primary">
            <Toolbar variant="dense">
              <IconButton onClick={toggleDrawer('left', true)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" color="inherit">
                KRM Cashews

          </Typography>
            </Toolbar>
          </AppBar>
          <div>
            <Route exact path="/home" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/products" component={Products} />
            <Route path="/Locate Us" component={Locate} />
            <Route exact path="/" component={Home} />
          </div>

          <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
            {sideList('left')}
          </Drawer>
        </ThemeProvider>
      </BrowserRouter></div>
  );
}