import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import BusinessIcon from '@material-ui/icons/Business';
import Map from './map'
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: 'auto',
      width: theme.spacing(32),
      height: theme.spacing(26),
    },
  },
}));
const Locate=()=> {
    const classes = useStyles();

    return (<><br></br>
        <div className={classes.root}>

<Paper elevation={3} ><h4><b color={"primary"}><u>Contuct Us</u></b></h4>


<MailIcon  color={"primary"}></MailIcon>&nbsp;<i>KRM.Cashews@gmail.com</i>
<br></br>
<PhoneIcon color={"primary"}></PhoneIcon>&nbsp;<i>9437658870</i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
<br></br>
<BusinessIcon color={"primary"}></BusinessIcon>&nbsp;<i>B L Puram,&nbsp; Kashinagar&nbsp;, Gajapathi district, Odisha, Pin:761206</i>
</Paper>
        </div>
        <br></br>
        
        <div style={{height:"100vh",width:"80%"}}>
        <Map></Map>
        
        </div>
        </>
    )
}

export default Locate
