import React, { Component } from 'react'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';






 class maps extends Component{
  
  
marks=[]



  render() {
    const position = [18.887437, 83.885516]

    return (
      <Map center={position} zoom={13} scrollWheelZoom={false}>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />  

     
<Marker position={[18.887437, 83.885516]}>
       <Popup>
        ks
       </Popup>
     </Marker>
      </Map>
    )
  }
}

export default maps